// 查询当天日期
export const getNowDate = (type) => {
    const timeOne = new Date()
    const year = timeOne.getFullYear()
    let month = timeOne.getMonth() + 1
    let day = timeOne.getDate()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    const date = `${year}-${month}-${day}`
    const dateArray = [year.toString(), month.toString(), day.toString()]
    if(type == 'array') {
        return dateArray
    }

    return date;
    // return [year.toString(), month.toString(), day.toString()]
}

// 是否pc  手机+iPad
export const isMobile = () => {
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        return true; // 手机+iPad
    }else{
        return false; // pc
    }
}

// 日期比较
export const compareDates = (date1, date2) => {
    var time1 = date1.getTime();
    var time2 = date2.getTime();

    if (time1 > time2) {
        return 1;
    } else if (time1 < time2) {
        return -1;
    } else {
        return 0;
    }
}
//
// var date1 = new Date("2022-01-01");
// var date2 = new Date("2022-01-02");
// console.log(compareDates(date1, date2));  // 输出 -1